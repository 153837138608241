import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Token, TableWidget } from '@revolut/ui-kit'

import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  KeyPersonResponsibilityInterface,
  KeyPersonResponsibilitiesStatsInterface,
} from '@src/interfaces/keyPerson'
import {
  keyPersonResponsibilityCategoryColumn,
  keyPersonResponsibilityRequiredForColumn,
  keyPersonResponsibilityNameColumn,
  keyPersonResponsibilityStatusColumn,
  keyPersonResponsibilitySuccessionColumn,
} from '@src/constants/columns/keyPersonsResponsibilities'
import { keyPersonsResponsibilitiesTableRequests } from '@src/api/keyPerson'
import { StatsConfig, useSelectableTableStats } from '@components/StatFilters/hooks'
import { StatFilters } from '@components/StatFilters/StatFilters'

const ROW: RowInterface<KeyPersonResponsibilityInterface> = {
  cells: [
    {
      ...keyPersonResponsibilityNameColumn,
      width: 300,
    },
    {
      ...keyPersonResponsibilityCategoryColumn,
      width: 200,
    },
    {
      ...keyPersonResponsibilityRequiredForColumn,
      width: 200,
    },
    {
      ...keyPersonResponsibilitySuccessionColumn,
      width: 200,
    },
    {
      ...keyPersonResponsibilityStatusColumn,
      width: 160,
    },
  ],
}

const statsConfig: StatsConfig<KeyPersonResponsibilitiesStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'active',
    title: 'Active',
    filterKey: 'active',
    color: Token.color.green,
  },
  {
    key: 'archived',
    title: 'Archived',
    filterKey: 'archived',
    color: Token.color.greyTone50,
  },
]

export const KeyPersonsResponsibilitiesTable = () => {
  const permissions = useSelector(selectPermissions)
  const table = useTable<
    KeyPersonResponsibilityInterface,
    KeyPersonResponsibilitiesStatsInterface
  >(keyPersonsResponsibilitiesTableRequests, [
    { columnName: 'active_stats', filters: [{ id: 'active', name: 'active' }] },
  ])
  const canAdd = permissions.includes(PermissionTypes.AddKeyPersonType)

  const statFiltersProps = useSelectableTableStats<
    KeyPersonResponsibilityInterface,
    KeyPersonResponsibilitiesStatsInterface
  >({
    table,
    statsConfig,
    columnName: 'active_stats',
  })

  return (
    <TableWidget>
      <TableWidget.Info>
        <StatFilters {...statFiltersProps} />
      </TableWidget.Info>
      {canAdd && (
        <TableWidget.Actions>
          <Button
            onClick={() => {
              navigateTo(pathToUrl(ROUTES.APPS.KEY_PERSONS.RESPONSIBILITY.EDIT))
            }}
            mr="s-16"
            useIcon="Plus"
            size="sm"
            variant="secondary"
          >
            Add responsibility
          </Button>
        </TableWidget.Actions>
      )}
      <TableWidget.Table>
        <AdjustableTable<
          KeyPersonResponsibilityInterface,
          KeyPersonResponsibilitiesStatsInterface
        >
          name={TableNames.KeyPersonsResponsibilities}
          useWindowScroll
          row={ROW}
          {...table}
          onRowClick={(row: { id: number }) => {
            navigateTo(
              pathToUrl(ROUTES.APPS.KEY_PERSONS.RESPONSIBILITY.PREVIEW, {
                id: row.id,
              }),
            )
          }}
          noDataMessage="All responsibilities will appear here"
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
