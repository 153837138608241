import React, { useEffect, useState } from 'react'
import {
  Item,
  Avatar,
  Text,
  Color,
  Button,
  MoreBar,
  Box,
  InputGroup,
  Footnote,
  ProgressWidget,
  VStack,
  Group,
  Flex,
} from '@revolut/ui-kit'
import {
  Profile,
  Envelope,
  Document,
  SandWatch,
  Check,
  StatusClockArrows,
  Calendar,
  Services,
  SubtractLogoRevolut,
  Resort,
  InfoOutline,
  BadgeRight,
} from '@revolut/icons'
import { useParams } from 'react-router-dom'
import startCase from 'lodash/startCase'
import { connect, useLape } from 'lape'

import {
  OnboardingTimelineEmployee,
  OnboardingTimelineInterface,
  OnboardingTimelineProcessStage,
  OnboardingTimelineStatus,
  ProcessStages,
  ProcessStageStep,
  ProcessStageSteps,
} from '@src/interfaces/onboarding'
import { TablePreview } from '@src/components/TablePreview/TablePreview'
import { getEmployeeDocuments } from '@src/api/accessRequests'
import { getDocumentStatusColor } from '@src/constants/columns/documents'
import {
  DocumentSources,
  DocumentStatuses,
  EmployeeDocumentListItemInterface,
} from '@src/interfaces/documents'
import { getDocumentLink } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import {
  EmployeeInterface,
  IdStatuses,
  ProbationTemplateOptionInterface,
} from '@src/interfaces/employees'
import { IdAndName } from '@src/interfaces'
import { completeStage, confirmOnboardingStep } from '@src/api/onboardingEmployees'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { employeesRequestsNew } from '@src/api/employees'
import SideBar from '@src/components/SideBar/SideBar'
import NewDatePicker from '@src/components/Inputs/NewDatePicker/NewDatePicker'
import pluralize from 'pluralize'
import { formatDateTime } from '@src/utils/format'
import { useGetLifecycleSettings } from '@src/api/settings'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useGetProbationTemplates } from '@src/api/probationTemplate'
import { renderTemplatesSelectorOption } from '../ProbationTemplate/common'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { getProbationEndDate } from '@src/utils/employees'
import { useSubmitFlowHelpers } from '../GoalForm/common/utils'

export type OpenedSidebar =
  | 'correspondence'
  | 'send-welcome-email'
  | 'send-day-1-instructions'
  | 'send-follow-up-email'
  | 'confirm-date'
  | 'confirm-start-date'
  | 'chat'
  | 'onboarding-details'
  | 'requested-documents'
  | 'review-position-info'
  | 'review-organisation-info'
  | 'transfer-ownership'
  | 'deadline'
  | null

export interface TabProps {
  stage: OnboardingTimelineProcessStage
  data: OnboardingTimelineInterface
  updateStep: (process: OnboardingTimelineInterface) => void
  updateStage: (stage: OnboardingTimelineProcessStage) => void
  updateEmployeeStatus: (status: IdAndName<IdStatuses>) => void
  openedSidebar: OpenedSidebar
  setOpenedSidebar: (state: OpenedSidebar) => void
}

export interface RouteParams {
  employeeId: string
  id: string
}

export const useRouteParams = () => useParams<RouteParams>()

const stepToAvatar = {
  [ProcessStageSteps.ReviewDetails]: Profile,
  [ProcessStageSteps.ReviewDocuments]: Document,
  [ProcessStageSteps.ConfirmAndRecordStartDate]: Calendar,
  [ProcessStageSteps.ReviewPositionInformation]: Services,
  [ProcessStageSteps.ReviewOrganisationInformation]: SubtractLogoRevolut,
  [ProcessStageSteps.AssignTimeOffPolicies]: Resort,
  [ProcessStageSteps.SendDay1Instructions]: Envelope,
}

const processStatusToIcon = {
  in_progress: StatusClockArrows,
  completed: Check,
  pending: SandWatch,
} as const

export const processStatusToColor = {
  in_progress: Color.ORANGE,
  completed: Color.GREEN,
  pending: Color.ORANGE,
  cancelled: Color.GREY_TONE_50,
}

interface ProcessStageStepItemProps {
  step: ProcessStageStep
  onClick: () => void
  disabled?: boolean
}

export const ProcessStageStepItem = ({
  step,
  onClick,
  disabled,
}: ProcessStageStepItemProps) => {
  const description = (() => {
    if (step.status.id === 'pending') {
      return <Text color={Color.ORANGE}>Pending</Text>
    }

    if (step.status.id === 'completed') {
      return `Completed by ${step.completed_by?.full_name}, ${formatDateTime(
        step.completed_on,
      )}`
    }

    return ''
  })()

  return (
    <Item
      use="button"
      variant="disclosure"
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <Item.Avatar>
        {/* @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */}
        <Avatar useIcon={stepToAvatar[step.step.id]}>
          <Avatar.Badge
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            useIcon={processStatusToIcon[step.status.id]}
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            bg={processStatusToColor[step.status.id]}
          />
        </Avatar>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{step.step.name}</Item.Title>
        <Item.Description>{description}</Item.Description>
      </Item.Content>
    </Item>
  )
}

export const documentStatusToIcon = {
  [DocumentStatuses.pending]: StatusClockArrows,
  [DocumentStatuses.pending_upload]: StatusClockArrows,
  [DocumentStatuses.pending_approval]: StatusClockArrows,
  [DocumentStatuses.completed]: Check,
}

export const documentStatusToColor = {
  [DocumentStatuses.pending]: Color.ORANGE,
  [DocumentStatuses.pending_upload]: Color.ORANGE,
  [DocumentStatuses.pending_approval]: Color.ORANGE,
  [DocumentStatuses.completed]: Color.GREEN,
}

interface DocumentsPreviewProps {
  type: 'generated' | 'requested'
  employee: OnboardingTimelineEmployee
  suffix?: React.ReactNode
}

const documentsEmptyState = {
  generated: 'No documents generated',
  requested: 'No documents requested',
}

const documentsFilters = {
  generated: [
    {
      filters: [
        { id: DocumentSources.Embedded, name: DocumentSources.Embedded },
        { id: DocumentSources.Docusign, name: DocumentSources.Docusign },
      ],
      columnName: 'source',
    },
  ],
  requested: [
    {
      filters: [
        { id: DocumentSources.Request, name: DocumentSources.Request },
        { id: DocumentSources.Upload, name: DocumentSources.Upload },
      ],
      columnName: 'source',
    },
  ],
}

const documentsTitle = {
  generated: 'Generated documents',
  requested: 'Requested documents',
}

export const DocumentsPreview = ({ type, employee, suffix }: DocumentsPreviewProps) => {
  const isCommercial = useIsCommercial()

  if (isCommercial && type === 'generated') {
    return null
  }
  return (
    <TablePreview<EmployeeDocumentListItemInterface>
      title={documentsTitle[type]}
      api={{
        getItems: requestData =>
          getEmployeeDocuments(
            {
              ...requestData,
              filters: documentsFilters[type],
            },
            employee.id,
          ),
      }}
      emptyState={{
        title: documentsEmptyState[type],
        icon: Document,
      }}
      row={document => {
        return (
          <Item use="button" onClick={() => getDocumentLink(document)} key={document.id}>
            <Item.Avatar>
              <Avatar useIcon={Document}>
                <Avatar.Badge
                  useIcon={documentStatusToIcon[document.status]}
                  bg={documentStatusToColor[document.status]}
                />
              </Avatar>
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{document.document_name}</Item.Title>
            </Item.Content>
            <Item.Side>
              <Item.Title color={getDocumentStatusColor(document.status)}>
                <Text fontWeight="normal">{startCase(document.status)}</Text>
              </Item.Title>
            </Item.Side>
          </Item>
        )
      }}
      suffix={suffix}
    />
  )
}

export const findProcessStage = (
  data: OnboardingTimelineInterface,
  stage: ProcessStages,
) => data.process_stages.find(s => s.stage.id === stage)

interface CompleteStageButtonProps extends TabProps {
  footnote?: string
  disabled?: boolean
  onClick?: () => void
  hidden?: boolean
  pending?: boolean
  children?: string
}

export const CompleteStageButton = ({
  footnote,
  disabled,
  onClick,
  hidden,
  pending,
  children = 'Complete step',
  ...props
}: CompleteStageButtonProps) => {
  const [completeStagePending, setCompleteStagePending] = useState(false)

  const params = useRouteParams()

  const rightToWorkStageExists = !!findProcessStage(props.data, ProcessStages.RightToWork)

  const nextRoute = {
    [ProcessStages.Work]: ROUTES.FORMS.ONBOARDING_TIMELINE.DOCUMENTS,
    [ProcessStages.Screening]: rightToWorkStageExists
      ? ROUTES.FORMS.ONBOARDING_TIMELINE.RIGHT_TO_WORK
      : ROUTES.FORMS.ONBOARDING_TIMELINE.FINISH,
    [ProcessStages.RightToWork]: ROUTES.FORMS.ONBOARDING_TIMELINE.FINISH,
  }

  const onCompleteStage = () => {
    setCompleteStagePending(true)
    completeStage(props.data.employee.id, props.stage.id)
      .then(response => {
        props.updateStage(response.data)
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        navigateReplace(pathToUrl(nextRoute[props.stage.stage.id], params))
      })
      .catch(() => {
        setCompleteStagePending(false)
      })
  }

  if (props.stage.status.id === 'completed' || hidden) {
    return null
  }

  return (
    <Box>
      <Button
        onClick={onClick || onCompleteStage}
        elevated
        pending={pending || completeStagePending}
        disabled={disabled}
        mb="s-16"
      >
        {children}
      </Button>
      {footnote ? <Footnote style={{ maxWidth: 350 }}>{footnote}</Footnote> : null}
    </Box>
  )
}

interface SendFollowUpEmailButtonProps {
  onClick: () => void
}

export const SendFollowUpEmailButton = ({ onClick }: SendFollowUpEmailButtonProps) => {
  return (
    <MoreBar.Action onClick={onClick} useIcon={Envelope}>
      Send follow up email
    </MoreBar.Action>
  )
}

interface StartDateSidebarProps {
  open: boolean
  onClose: () => void
  step?: ProcessStageStep
  candidateId: number
  data: OnboardingTimelineInterface
  updateStep?: (process: OnboardingTimelineInterface) => void
  refetchProcess?: () => void
}

export const StartDateSidebar = connect(
  ({
    open,
    onClose,
    step,
    candidateId,
    data,
    updateStep,
    refetchProcess,
  }: StartDateSidebarProps) => {
    const [confirmDatePending, setConfirmDatePending] = useState(false)
    const candidate = data.employee
    const employeeType = data.employee.employee_type
    const { data: templates } = useGetProbationTemplates({
      seniority_id: candidate.seniority?.id,
      contract_type: candidate.contract_type?.id,
      employee_type: employeeType,
      specialisation_id: candidate.specialisation?.id,
      team_id: candidate.team?.id,
      location_id: candidate.location?.id,
      entity_id: candidate.entity?.id,
    })
    const { confirmationDialog, confirm } = useSubmitFlowHelpers()

    const form = useLape<{
      joining_date_time: EmployeeInterface['joining_date_time'] | null
      probation_template: EmployeeInterface['probation_template'] | null
      end_of_probation_date_time: EmployeeInterface['end_of_probation_date_time'] | null
    }>({
      joining_date_time: data.employee.joining_date_time,
      probation_template: data.employee.probation_template,
      end_of_probation_date_time: data.employee.end_of_probation_date_time,
    })

    useEffect(() => {
      if (
        templates &&
        form.probation_template &&
        !form.probation_template?.amount_of_months
      ) {
        // BE doesn't send amount_of_months with employee template
        // so user's current template would be without amount_of_months
        // but we need this field in oder to count new end_of_probation_date_time when  joining_date_time has changed
        const selectedTemplate = templates.find(t => t.id === form.probation_template?.id)
        form.probation_template.amount_of_months = selectedTemplate?.amount_of_months
      }
    }, [templates])

    const onConfirmClick = async () => {
      if (!form.probation_template) {
        const { status } = await confirm({
          label: 'Please, confirm',
          body: 'This employee will not have probation cycle',
          yesMessage: 'Confirm',
          noMessage: 'Back',
          variant: 'compact',
        })

        if (status === 'canceled' || status === 'rejected') {
          return
        }
      }

      setConfirmDatePending(true)
      try {
        await employeesRequestsNew.update(form as EmployeeInterface, {
          id: `${data.employee.id}`,
        })
        if (step && step.status.id !== 'completed') {
          const confirmResponse = await confirmOnboardingStep(data.employee.id, step.id)
          updateStep?.(confirmResponse.data.process)
        }
        onClose()
        refetchProcess?.()
      } finally {
        setConfirmDatePending(false)
      }
    }

    const onProbationTemplateChanged = (
      template: ProbationTemplateOptionInterface | null,
    ) => {
      form.probation_template = template

      if (!template) {
        form.end_of_probation_date_time = null
      } else if (form.joining_date_time && template.amount_of_months) {
        form.end_of_probation_date_time = getProbationEndDate(
          form.joining_date_time,
          template.amount_of_months,
        )
      }
    }

    const onStartDateChanged = (date?: Date) => {
      const startDate = date ? date.toISOString() : null
      form.end_of_probation_date_time =
        startDate && form.probation_template?.amount_of_months
          ? getProbationEndDate(startDate, form.probation_template.amount_of_months)
          : null
      form.joining_date_time = startDate
    }

    return (
      <SideBar title="Start date" isOpen={open} onClose={onClose} variant="wide">
        <MoreBar>
          <MoreBar.Action
            useIcon={Check}
            onClick={onConfirmClick}
            variant="accent"
            pending={confirmDatePending}
            disabled={!form.joining_date_time}
          >
            Confirm
          </MoreBar.Action>
          <MoreBar.Action
            onClick={() =>
              navigateTo(pathToUrl(ROUTES.FORMS.SEND_EMAIL.CANDIDATE, { candidateId }))
            }
            useIcon={Envelope}
          >
            Contact candidate
          </MoreBar.Action>
        </MoreBar>

        <Box mt="s-16">
          <InputGroup>
            <NewDatePicker
              label="Start date"
              value={form.joining_date_time || ''}
              onChange={onStartDateChanged}
            />
            <RadioSelectInput
              label="Select probation template"
              value={form.probation_template}
              onChange={onProbationTemplateChanged}
              options={templates?.map(item => ({
                label: item.name,
                value: item,
              }))}
              disableOptionRule={option => !option.value.is_eligible}
              clearable
            >
              {renderTemplatesSelectorOption}
            </RadioSelectInput>
          </InputGroup>
        </Box>
        {confirmationDialog}
      </SideBar>
    )
  },
)

interface SLAProgressProps {
  elapsedSla: number
  sla: number
  caption: string
  onSidebarOpen: () => void
}

export const SLAProgress = ({
  sla,
  elapsedSla,
  caption,
  onSidebarOpen,
}: SLAProgressProps) => {
  const { data: lifecycleSettings } = useGetLifecycleSettings()

  if (!lifecycleSettings?.enable_sla_tracking) {
    return null
  }

  const progress = elapsedSla / sla
  const displayProgress = Math.min(1, progress)

  const color = (() => {
    if (progress > 1) {
      return Color.RED
    }
    if (progress >= 0.7 && progress <= 1) {
      return Color.ORANGE
    }
    return Color.BLUE
  })()

  return (
    <Box mb="s-16">
      <Group>
        <ProgressWidget>
          <ProgressWidget.Title>
            <VStack>
              <Text>SLA for this step is {pluralize('hour', sla, true)}</Text>
              <Text variant="caption" color="grey-tone-50">
                {caption}
              </Text>
            </VStack>
          </ProgressWidget.Title>
          <ProgressWidget.Description>
            {elapsedSla}/{sla} hours
          </ProgressWidget.Description>
          <ProgressWidget.Progress value={displayProgress} color={color} />
        </ProgressWidget>

        {progress > 1 ? (
          <Box mt="-s-16">
            <ActionWidget
              title={
                <Flex gap="s-8" alignItems="center">
                  <Text>SLA for this step was breached</Text>
                  <InfoOutline color={Color.RED} size={16} />
                </Flex>
              }
              avatar={
                <Button
                  onClick={onSidebarOpen}
                  size="sm"
                  variant="text"
                  useIcon={BadgeRight}
                  mt="-s-8"
                >
                  Open SLA timeline
                </Button>
              }
              text={
                <Text color={Color.FOREGROUND}>
                  Check onboarding timeline to verify how it impacts other steps of the
                  process.
                </Text>
              }
            />
          </Box>
        ) : null}
      </Group>
    </Box>
  )
}

interface SkippedStageWidgetProps {
  status: OnboardingTimelineStatus
}

export const SkippedStageWidget = ({ status }: SkippedStageWidgetProps) => {
  if (status === 'skipped') {
    return (
      <ActionWidget
        title="Step not completed"
        text="This step was enabled after the onboarding process was finished"
        mb="s-16"
      />
    )
  }
  return null
}
