import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Cell, Flex } from '@revolut/ui-kit'

import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'
import { engagementQuestionsTableRequests } from '@src/api/engagement'
import {
  engagementQuestionText,
  engagementQuestionDriver,
  engagementQuestionIsCommentRequired,
  engagementQuestionUpdatedOn,
  engagementQuestionOwner,
  engagementQuestionStatus,
  engagementQuestionType,
} from '@src/constants/columns/engagementQuestions'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import AdjustableTable from '@components/Table/AdjustableTable'
import pluralize from 'pluralize'
import Stat from '@components/Stat/Stat'
import { TableNames } from '@src/constants/table'

const ROW: RowInterface<EngagementQuestionInterface> = {
  cells: [
    {
      ...engagementQuestionText,
      width: 400,
    },
    {
      ...engagementQuestionDriver,
      width: 250,
    },
    {
      ...engagementQuestionType,
      width: 250,
    },
    {
      ...engagementQuestionIsCommentRequired,
      width: 220,
    },
    {
      ...engagementQuestionUpdatedOn,
      width: 200,
    },
    {
      ...engagementQuestionOwner,
      width: 250,
    },
    {
      ...engagementQuestionStatus,
      width: 250,
    },
  ],
}

export const QuestionsTable = () => {
  const permissions = useSelector(selectPermissions)
  const table = useTable<EngagementQuestionInterface>(engagementQuestionsTableRequests)

  const canAdd = permissions.includes(PermissionTypes.ChangeEngagement)

  return (
    <Cell p="s-16">
      <Flex width="100%">
        <Flex flexDirection="column" width="100%">
          <Stat
            label={pluralize('Question', table.count)}
            val={table.loading ? undefined : table.count}
          />
          {canAdd && (
            <Flex my="s-16">
              <Button
                onClick={() => {
                  navigateTo(pathToUrl(ROUTES.APPS.ENGAGEMENT.QUESTION.EDIT))
                }}
                mr="s-16"
                useIcon="Plus"
                size="sm"
                variant="secondary"
              >
                Add new question
              </Button>
            </Flex>
          )}
          <Flex style={{ position: 'relative' }} flex="1 0">
            <AdjustableTable<EngagementQuestionInterface>
              name={TableNames.EngagementQuestions}
              useWindowScroll
              row={ROW}
              {...table}
              onRowClick={(row: { id: number }) => {
                navigateTo(
                  pathToUrl(ROUTES.APPS.ENGAGEMENT.QUESTION.PREVIEW, { id: row.id }),
                )
              }}
              noDataMessage="All engagement questions will appear here"
            />
          </Flex>
        </Flex>
      </Flex>
    </Cell>
  )
}
