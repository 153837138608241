import React, { useState } from 'react'
import {
  ActionButton,
  IconButton,
  Input,
  Item,
  List,
  Token,
  VStack,
  Text,
  HStack,
  Button,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ServiceDeskCategoryInterface } from '@src/interfaces/faq'
import { serviceDeskSubcategoriesRequest } from '@src/api/faq'
import { useParams } from 'react-router-dom'

export const Subcategories = ({ isEditing }: { isEditing?: boolean }) => {
  const { values } = useLapeContext<ServiceDeskCategoryInterface>()
  const params = useParams<{ id?: string }>()

  const isNew = !params.id

  const [newName, setNewName] = useState('')
  const [newLink, setNewLink] = useState('')
  const [isAdding, setIsAdding] = useState(false)
  const [deletingId, setDeletingId] = useState<number>()

  const onAdd = async () => {
    if (!values.id) {
      return
    }
    setIsAdding(true)
    try {
      const response = await serviceDeskSubcategoriesRequest.submit(
        {
          name: newName,
          link: newLink,
        },
        {
          categoryId: `${values.id}`,
        },
      )

      values.subcategories = [...(values?.subcategories || []), response.data]
      setNewName('')
      setNewLink('')
    } finally {
      setIsAdding(false)
    }
  }

  const onDelete = async (subcategoryId: number) => {
    if (!values.id) {
      return
    }

    setDeletingId(subcategoryId)
    try {
      await serviceDeskSubcategoriesRequest.delete?.(
        {},
        { categoryId: `${values.id}`, subcategoryId: `${subcategoryId}` },
      )

      values.subcategories = values.subcategories?.filter(
        subcat => subcat.id !== Number(subcategoryId),
      )
    } finally {
      setDeletingId(undefined)
    }
  }

  if (isNew) {
    return <Item>Category must be saved before you can add subcategories</Item>
  }

  if (values.id && values.subcategories?.length === 0 && !isEditing) {
    return <Item>Click on "Edit" to add subcategories</Item>
  }

  return (
    <List gap="s-8">
      {values.subcategories?.map(subcategory => (
        <Item key={subcategory.id}>
          <Item.Content>
            <Item.Title>{subcategory.name}</Item.Title>
            <Item.Description>
              <HStack gap="s-2" align="center">
                <Text>{subcategory.link}</Text>
                <IconButton
                  use="a"
                  target="_blank"
                  href={subcategory.link}
                  size={16}
                  color={Token.color.blue}
                  useIcon="LinkExternal"
                />
              </HStack>
            </Item.Description>
          </Item.Content>
          {isEditing && (
            <Item.Side>
              <Button
                variant="negative"
                size="sm"
                useIcon="Delete"
                pending={deletingId === subcategory.id}
                aria-label="Delete subcategory"
                onClick={() => {
                  onDelete(subcategory.id)
                }}
              />
            </Item.Side>
          )}
        </Item>
      ))}
      {isEditing && (
        <Item>
          <VStack gap="s-6" width="100%">
            <Input
              label="New subcategory name"
              value={newName}
              onChange={e => setNewName(e.currentTarget.value)}
            />
            <Input
              label="New subcategory link"
              value={newLink}
              onChange={e => setNewLink(e.currentTarget.value)}
            />
            <ActionButton pending={isAdding} onClick={onAdd}>
              Add new subcategory
            </ActionButton>
          </VStack>
        </Item>
      )}
    </List>
  )
}
