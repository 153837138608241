import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ActionButton, Bar, Button, Color, TableWidget } from '@revolut/ui-kit'

import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  KeyPersonResponsibilityAssignmentInterface,
  KeyPersonResponsibilityAssignmentsStatsInterface,
} from '@src/interfaces/keyPerson'
import {
  exportResponsibilitiesAssignmentsData,
  keyPersonsResponsibilitiesAssignmentsTableRequests,
} from '@src/api/keyPerson'
import { StatsConfig, useSelectableTableStats } from '@components/StatFilters/hooks'
import { StatFilters } from '@components/StatFilters/StatFilters'
import {
  keyPersonsResponsibilitiesAssignmentsActionsColumn,
  keyPersonsResponsibilitiesAssignmentsEmployeeColumn,
  keyPersonsResponsibilitiesAssignmentsResponsibilityColumn,
  keyPersonsResponsibilitiesAssignmentsUnitColumn,
  keyPersonsResponsibilitiesAssignmentsStartDateColumn,
  keyPersonsResponsibilitiesAssignmentsEndDateColumn,
  keyPersonsResponsibilitiesAssignmentsPeriodColumn,
  keyPersonsResponsibilitiesAssignmentsSuccessionColumn,
  keyPersonsResponsibilitiesAssignmentsStatusColumn,
  keyPersonsResponsibilitiesAssignmentsResponsibilityCategoryColumn,
  keyPersonsResponsibilitiesAssignmentsEmployeeStatusColumn,
} from '@src/constants/columns/keyPersonsResponsibilitiesAssignments'
import { ResponsibilityAssignmentSidebar } from '@src/apps/People/KeyPersons/ResponsibilityAssignmentsTable/ResponsibilityAssignmentSidebar'
import { getColor } from '@src/styles/colors'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { saveFileByBlob } from '@src/utils'
import { navigateTo } from '@src/actions/RouterActions'
import { ActionButtons } from '@src/apps/People/KeyPersons/ResponsibilityAssignmentsTable/ActionButtons'
import { useFilterButtons } from '@src/apps/People/KeyPersons/Responsibility/hooks'

const getRow = ({
  onEdit,
  refresh,
}: {
  refresh: () => void
  onEdit: (data: KeyPersonResponsibilityAssignmentInterface) => void
}): RowInterface<KeyPersonResponsibilityAssignmentInterface> => ({
  highlight: (data, theme) => {
    const highlightUnassigned = !data.employee || data.employee.status === 'terminated'
    return highlightUnassigned ? getColor(theme, 'red-opaque-10') : ''
  },
  linkToForm: (data: KeyPersonResponsibilityAssignmentInterface) =>
    data.employee?.id
      ? navigateTo(
          `${pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, {
            id: data.employee.id,
          })}#keyPerson`,
        )
      : undefined,
  cells: [
    {
      ...keyPersonsResponsibilitiesAssignmentsEmployeeColumn,
      width: 220,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsEmployeeStatusColumn,
      width: 150,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsResponsibilityColumn,
      width: 240,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsResponsibilityCategoryColumn,
      width: 220,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsUnitColumn,
      width: 220,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsStartDateColumn,
      width: 180,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsEndDateColumn,
      width: 180,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsPeriodColumn,
      width: 180,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsSuccessionColumn,
      width: 100,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsStatusColumn,
      width: 160,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsActionsColumn,
      width: 160,
      insert: ({ data }) => (
        <ActionButtons data={data} refresh={refresh} onEdit={onEdit} />
      ),
    },
  ],
})

const statsConfig: StatsConfig<KeyPersonResponsibilityAssignmentsStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Color.FOREGROUND,
  },
  {
    key: 'unassigned',
    title: 'Unassigned',
    filterKey: 'unassigned',
    color: Color.ORANGE,
  },
  {
    key: 'pending_start',
    title: 'Pending start',
    filterKey: 'pending_start',
    color: Color.ORANGE,
  },
  {
    key: 'active',
    title: 'Active',
    filterKey: 'active',
    color: Color.GREEN,
  },
  {
    key: 'expired',
    title: 'Expired',
    filterKey: 'expired',
    color: Color.RED,
  },
  {
    key: 'archived',
    title: 'Archived',
    filterKey: 'archived',
    color: Color.GREY_TONE_50,
  },
]

export const KeyPersonsResponsibilityAssignmentsTable = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [assignmentId, setAssignmentId] = useState<number>()
  const [isExportDataPending, setIsExportDataPending] = useState(false)

  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddKeyRiskProfile)

  const table = useTable<
    KeyPersonResponsibilityAssignmentInterface,
    KeyPersonResponsibilityAssignmentsStatsInterface
  >(keyPersonsResponsibilitiesAssignmentsTableRequests, [
    { columnName: 'active_stats', filters: [{ id: 'unassigned', name: 'unassigned' }] },
  ])

  const filtersButtons = useFilterButtons<
    KeyPersonResponsibilityAssignmentInterface,
    KeyPersonResponsibilityAssignmentsStatsInterface
  >(table)

  const statFiltersProps = useSelectableTableStats<
    KeyPersonResponsibilityAssignmentInterface,
    KeyPersonResponsibilityAssignmentsStatsInterface
  >({
    table,
    statsConfig,
    columnName: 'active_stats',
  })

  const onExportResponsibilitiesData = () => {
    setIsExportDataPending(true)
    exportResponsibilitiesAssignmentsData()
      .then(response => {
        saveFileByBlob(
          response.data,
          'KeyRiskProfile.csv',
          response.headers['content-type'],
        )
      })
      .finally(() => setIsExportDataPending(false))
  }

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <StatFilters {...statFiltersProps} />
        </TableWidget.Info>
        <TableWidget.Actions>
          <Bar>
            {canAdd && (
              <Button
                onClick={() => {
                  setIsSidebarOpen(true)
                }}
                useIcon="Plus"
                size="sm"
                variant="secondary"
              >
                Assign responsibility
              </Button>
            )}
            <ActionButton
              useIcon="Download"
              pending={isExportDataPending}
              onClick={onExportResponsibilitiesData}
            >
              Download
            </ActionButton>
          </Bar>
        </TableWidget.Actions>
        <TableWidget.Filters>{filtersButtons}</TableWidget.Filters>
        <TableWidget.Table>
          <AdjustableTable<
            KeyPersonResponsibilityAssignmentInterface,
            KeyPersonResponsibilityAssignmentsStatsInterface
          >
            name={TableNames.KeyPersonsSuccession}
            useWindowScroll
            row={getRow({
              onEdit: data => {
                setAssignmentId(data.id)
                setIsSidebarOpen(true)
              },
              refresh: () => table.refresh(),
            })}
            {...table}
            noDataMessage="All assignments will appear here"
          />
        </TableWidget.Table>
      </TableWidget>
      <ResponsibilityAssignmentSidebar
        assignmentId={assignmentId}
        isOpen={isSidebarOpen}
        onClose={() => {
          setIsSidebarOpen(false)
          setAssignmentId(undefined)
        }}
        onUpdateResponsibility={() => {
          table.refresh()
        }}
      />
    </>
  )
}
