import { DetailsCell, Icon, Token, Text, HStack, TableWidget } from '@revolut/ui-kit'

import { GoalsStats, goalsListTableRequests } from '@src/api/goals'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import {
  goalsNameColumn,
  goalsOrgUnitColumn,
  goalsWeightColumn,
  goalsChangeStatusColumn,
  goalsProgressColumn,
  singleTargetAdditionalColumns,
  goalsOwnerColumn,
  goalsApprovalStatusColumn,
} from '@src/constants/columns/goals'
import { TableNames } from '@src/constants/table'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'

import { FilterByInterface, FilterOption, RowInterface } from '@src/interfaces/data'
import { GoalsInterface } from '@src/interfaces/goals'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'

import React, { useEffect } from 'react'
import { GoalCycleSelectType } from './GoalPreviewPage'
import { getGoalUrl } from './helpers'

export const ChildGoalsPreviewWidget = ({
  parentId,
  cycle,
}: {
  parentId: number
  cycle: GoalCycleSelectType
}) => {
  const isOnboarding = isOnboardingPath()

  const { data: performanceSettings } = useGetPerformanceSettings()
  const isMultipleGoalsTargetsEnabled =
    performanceSettings?.enable_multiple_goal_targets_per_cycle

  const { entity } = useOrgEntity()

  const filters: FilterByInterface[] = [
    {
      filters: [{ name: 'parent_id', id: parentId }],
      columnName: 'parent_id',
      nonInheritable: true,
      nonResettable: true,
    },
    {
      filters: [{ name: `True`, id: 'True' }],
      columnName: 'is_company',
      nonResettable: true,
      nonInheritable: true,
    },
    {
      columnName: 'cycle__id',
      filters: [{ id: cycle.id, name: cycle.name }] as FilterOption[],
    },
  ]

  const table = useTable(goalsListTableRequests, filters, undefined, {
    parentIdFilterKey: 'parent_id',
    omitKeys: ['is_top_level', 'cycle__id', 'parent_id'],
  })

  useEffect(() => {
    table.onFilterChange(filters)
  }, [cycle])

  // TODO: https://revolut.atlassian.net/browse/REVC-6260
  // allow editing weights
  const row: RowInterface<GoalsInterface> = {
    linkToForm: goal => getGoalUrl(goal, isOnboarding),
    noChildrenRequest: true,
    cells: [
      {
        ...goalsNameColumn(),
        width: 300,
      },
      {
        ...goalsOrgUnitColumn({ companyName: entity?.data.name || '' }),
        width: 150,
      },
      {
        ...goalsWeightColumn,
        width: 70,
      },
      {
        ...goalsChangeStatusColumn((status, goalId) =>
          table.updateRows(
            r => r.id === goalId,
            r => ({ ...r, status }),
          ),
        ),
        width: 100,
      },
      {
        ...goalsProgressColumn,
        width: 100,
      },
      ...(!isMultipleGoalsTargetsEnabled ? singleTargetAdditionalColumns : []),
      {
        ...goalsOwnerColumn,
        width: 150,
      },
      {
        ...goalsApprovalStatusColumn,
        width: 50,
      },
    ],
  }

  return (
    <DetailsCell>
      <DetailsCell.Title>
        <HStack space="s-8">
          <Icon name="TurboTransfer" color={Token.color.greyTone20} />
          <Text variant="h6" color={Token.color.greyTone50}>
            Goals
          </Text>
        </HStack>
      </DetailsCell.Title>
      <DetailsCell.Note>
        <TableWidget>
          <TableWidget.Table>
            <AdjustableTable<GoalsInterface, GoalsStats>
              name={TableNames.Goals}
              useWindowScroll
              dataType="goals"
              row={row}
              noDataMessage="Add child goals to see them here."
              {...table}
            />
          </TableWidget.Table>
        </TableWidget>
      </DetailsCell.Note>
    </DetailsCell>
  )
}
