import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { MoreBar } from '@revolut/ui-kit'

import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { EditableEmployeesTableDetailed } from '@src/features/EditableEmployeesTable/TableDetailed'
import EmployeeTable from './EmployeeTable'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'

const EmployeesBase = () => {
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const isEditableTable =
    featureFlags.includes(FeatureFlags.EditableEmployeesTable) ||
    featureFlags.includes(FeatureFlags.OnboardingFlowV2)

  const [isEditing, setIsEditing] = useState(false)

  const canViewEmployeePreferences = permissions.includes(
    PermissionTypes.ViewEmployeePreferences,
  )

  return isEditableTable ? (
    <EditableEmployeesTableDetailed
      useMainAddForm
      sessionRoute={ROUTES.APPS.EMPLOYEES.BULK_UPDATE_EMPLOYEES.SESSION}
      isPreview={!isEditing}
      previewActions={
        <>
          <MoreBar.Action useIcon="Pencil" onClick={() => setIsEditing(true)}>
            Edit
          </MoreBar.Action>
          <MoreBar.Action
            useIcon="Upload"
            use={InternalLink}
            to={ROUTES.APPS.EMPLOYEES.BULK_UPDATE_EMPLOYEES.SELECT_FLOW}
          >
            Import employees
          </MoreBar.Action>
          <MoreBar.Action
            to={pathToUrl(ROUTES.FORMS.EMPLOYEES_ORG_CHART)}
            use={InternalLink}
            useIcon="People"
          >
            View org chart
          </MoreBar.Action>
          {canViewEmployeePreferences && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.SETTINGS.EMPLOYEES.GENERAL)}
              use={InternalLink}
              useIcon="Gear"
            >
              Settings
            </MoreBar.Action>
          )}
        </>
      }
      renderEditActionsLeft={() => (
        <MoreBar.Action useIcon="Check" onClick={() => setIsEditing(false)}>
          Done
        </MoreBar.Action>
      )}
    />
  ) : (
    <EmployeeTable />
  )
}

export const Employees = () => {
  return (
    <OnboardingAppScreen category="employeeRecords">
      <EmployeesBase />
    </OnboardingAppScreen>
  )
}
