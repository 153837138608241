import React, { useState } from 'react'
import { Box, Button, IconButton, Text, Token } from '@revolut/ui-kit'

import { SummarySkillCardInterface } from '@src/interfaces/performance'
import CommentsPopup from './CommentsPopup'

interface Props {
  data: SummarySkillCardInterface
  asText?: boolean
}

const SummaryJustification = ({ data, asText = false }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const filteredData = {
    ...data,
    justification: data.justification?.filter(item => item.value),
    justifications: data.justifications?.filter(item => item.value?.length),
  }

  if (!(filteredData.justification?.length || filteredData.justifications?.length)) {
    return <Text color={Token.color.greyTone20}>–</Text>
  }

  const setOpen = () => setIsOpen(true)

  return (
    <Box width={20}>
      {asText ? (
        <Button onClick={setOpen} variant="text" size="sm">
          View
        </Button>
      ) : (
        <IconButton
          onClick={setOpen}
          useIcon="Message"
          size={14}
          color={Token.color.greyTone50}
        />
      )}
      <CommentsPopup
        data={filteredData}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
      />
    </Box>
  )
}

export default SummaryJustification
