import React, { ReactNode, useRef, useState } from 'react'
import { Button, ButtonProps, Dropdown } from '@revolut/ui-kit'

import { OptionInterface } from '@src/interfaces/selectors'
import { IdAndName } from '@src/interfaces'

interface DropdownButtonProps<T = OptionInterface> extends Omit<ButtonProps, 'onSelect'> {
  children: ReactNode
  options: T[]
  onSelect: (option: T) => void
}

export const DropdownButton = <T extends IdAndName<number | string>>({
  children,
  options,
  onSelect,
  variant = 'secondary',
  ...props
}: DropdownButtonProps<T>) => {
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <Button
        variant={variant}
        ref={ref}
        onClick={() => setOpen(!isOpen)}
        size="sm"
        useEndIcon="ChevronDown"
        {...props}
      >
        {children}
      </Button>
      <Dropdown
        open={isOpen}
        anchorRef={ref}
        onClose={() => setOpen(false)}
        data-testid="dropdown-buttons"
      >
        {options.map(option => (
          <Dropdown.Item
            key={option.id}
            onClick={() => {
              onSelect(option)
              setOpen(false)
            }}
            use="button"
            data-testid={`dropdown-button-item-${option.id}`}
          >
            {option.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  )
}
