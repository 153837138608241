import React from 'react'
import {
  AppIcon,
  Button,
  Flex,
  Subheader,
  Widget,
  Grid,
  Text,
  AppIconSkeleton,
  LineClamp,
  Avatar,
  Ellipsis,
  StatusWidget,
  times,
  Token,
} from '@revolut/ui-kit'
import styled, { css } from 'styled-components'
import globalSearchState from '@components/GlobalSearchSidebar/GlobalSearchSidebarState'
import { useGetFavourites } from '@src/api/favourites'
import { getGlobalSearchItemFormUrl } from '@src/components/GlobalSearchSidebar/GlobalSearchItem/common'
import {
  InternalLink,
  InternalUIKitLink,
} from '@src/components/InternalLink/InternalLink'
import { getInitials } from '@src/utils/employees'
import { Customise } from '@components/Favourites/Customise'
import { getFavouriteTypeIconProps } from '@components/Favourites/common'

const SkeletonGridCss = css`
  grid-template-rows: auto;
  grid-auto-rows: 0px;
  row-gap: 0px;
  overflow: hidden;
`

const FavouritesWidget = styled(Grid)`
  column-gap: 0.5rem;
  row-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(4.5rem, auto));
  place-content: space-between;
`

const APP_ICON_SIZE = 60

const EmployeeIconText = styled(Ellipsis)`
  white-space: pre-wrap;
  text-align: center;
  font-size: ${props => props.theme.fontSizes.small};
  margin-top: ${Token.space.s4};
`

interface EmployeeIconProps {
  name: string
  displayName: string | null
  url?: string
  image?: string
}

const EmployeeIcon = ({ name, displayName, url, image }: EmployeeIconProps) => {
  const initials = getInitials(name)
  return (
    <Flex width={APP_ICON_SIZE} flexDirection="column">
      <Avatar
        image={image}
        label={initials}
        aria-label={name}
        to={url}
        use={InternalUIKitLink}
        size={APP_ICON_SIZE}
      />
      <EmployeeIconText>
        <LineClamp max={2} tooltip="auto">
          {displayName || name}
        </LineClamp>
      </EmployeeIconText>
    </Flex>
  )
}

const Favourites = () => {
  const { data, isLoading } = useGetFavourites()

  if (isLoading) {
    return (
      <Widget px="s-16" pb="s-16">
        <Subheader variant="nested">
          <Subheader.Title>Favourites</Subheader.Title>
        </Subheader>
        <FavouritesWidget css={SkeletonGridCss}>
          {times(
            index => (
              <AppIconSkeleton size={APP_ICON_SIZE} key={index} />
            ),
            15,
          )}
        </FavouritesWidget>
      </Widget>
    )
  }

  const hasFavourites = data?.count && data.count > 0

  const onAddFavourites = () => {
    globalSearchState.open = true
  }

  return (
    <Flex flexDirection="column">
      <Widget px="s-16" pb="s-16">
        <Subheader variant="nested">
          <Subheader.Title>Favourites</Subheader.Title>
          {hasFavourites ? <Customise /> : null}
        </Subheader>
        {hasFavourites ? null : (
          <StatusWidget>
            <StatusWidget.Title>Your favourites will appear here</StatusWidget.Title>
            <StatusWidget.Description>
              Search for items and use star to add them to your favourites
            </StatusWidget.Description>
            <StatusWidget.Action>
              <Button
                onClick={onAddFavourites}
                useIcon="Plus"
                variant="secondary"
                size="sm"
              >
                Add favourites
              </Button>
            </StatusWidget.Action>
          </StatusWidget>
        )}
        <FavouritesWidget>
          {data?.results.map(favourite => {
            const isEmployee = favourite.favourite_object.category.id === 'employee'

            const url = getGlobalSearchItemFormUrl(
              favourite.favourite_object.category.id,
              favourite.favourite_object.object_id,
            )

            return (
              <Flex width={72} justifyContent="center" key={favourite.id}>
                {isEmployee ? (
                  <EmployeeIcon
                    name={favourite.favourite_object.object_name}
                    displayName={favourite.display_name}
                    image={favourite.favourite_object.thumbnail_url}
                    url={url}
                  />
                ) : (
                  <AppIcon
                    to={url}
                    useIcon={
                      getFavouriteTypeIconProps(favourite.favourite_object.category.id)[0]
                    }
                    bg={
                      getFavouriteTypeIconProps(favourite.favourite_object.category.id)[1]
                    }
                    size={APP_ICON_SIZE}
                    use={InternalLink}
                    aria-label={favourite.favourite_object.object_name}
                  >
                    <AppIcon.Title>
                      <Text whiteSpace="pre-wrap">
                        <LineClamp max={2} tooltip="auto">
                          {favourite.display_name ||
                            favourite.favourite_object.object_name}
                        </LineClamp>
                      </Text>
                    </AppIcon.Title>
                  </AppIcon>
                )}
              </Flex>
            )
          })}
        </FavouritesWidget>
      </Widget>
    </Flex>
  )
}

export default Favourites
