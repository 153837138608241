import React from 'react'
import { TableWidget, Token } from '@revolut/ui-kit'

import { API, selectorKeys } from '@src/constants/api'
import { BulkEditExistingEntitiesAction } from '@src/features/GenericEditableTable/components'
import { getBulkSessionFieldsMap } from '@src/features/GenericEditableTable/helpers'
import { TableView } from '../common/TableView'
import { EditableEmployeesTableProps } from '../common'
import { row, mandatoryBulkSessionFields, bulkFieldsDependencies } from './common'
import { StatsConfig, useSelectableTableStats } from '@components/StatFilters/hooks'
import {
  EmployeesSimpleInterface,
  EmployeeSimpleStatsFormattedInterface,
} from '@src/interfaces/employees'
import { useTable } from '@components/Table/hooks'
import { StatFilters } from '@components/StatFilters/StatFilters'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { getEditableTable } from '@src/features/GenericEditableTable/api'
import { getEmployeesSimpleStats } from '@src/api/employees'

const statsConfig: StatsConfig<EmployeeSimpleStatsFormattedInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'active',
    title: 'Active',
    filterKey: 'active',
    color: Token.color.green,
  },
  {
    key: 'pending',
    title: 'Pending',
    filterKey: 'pending',
    color: Token.color.orange,
  },
  {
    key: 'onboarding',
    title: 'Onboarding',
    filterKey: 'onboarding',
    color: Token.color.orange,
  },
  {
    key: 'terminated',
    title: 'Terminated',
    filterKey: 'terminated',
    color: Token.color.orange,
  },
  {
    key: 'active_externals',
    title: 'Active externals',
    filterKey: 'active_externals',
    color: Token.color.orange,
  },
  {
    key: 'hired',
    title: 'Hired',
    filterKey: 'hired',
    color: Token.color.orange,
  },
]

type Props = {
  sessionRoute: string
} & Omit<
  EditableEmployeesTableProps,
  'row' | 'renderEditActionsRight' | 'bulkSessionFieldsMap'
>
export const EditableEmployeesTableDetailed = ({
  sessionRoute,
  previewActions,
  renderEditActionsLeft,
  useMainAddForm,
  isPreview,
}: Props) => {
  const bulkSessionFieldsMap = getBulkSessionFieldsMap(
    mandatoryBulkSessionFields,
    bulkFieldsDependencies,
  )

  const table = useTable<
    ImportInterface<EmployeesSimpleInterface>,
    EmployeeSimpleStatsFormattedInterface
  >({
    getItems: getEditableTable(API.EMPLOYEES_SIMPLE),
    getStats: getEmployeesSimpleStats,
  })

  const statFiltersProps = useSelectableTableStats<
    ImportInterface<EmployeesSimpleInterface>,
    EmployeeSimpleStatsFormattedInterface
  >({
    table,
    statsConfig,
    columnName: 'active_stats',
    totalKey: 'total',
  })

  return (
    <TableView
      row={row}
      isDetailed
      isPreview={isPreview}
      sessionRoute={sessionRoute}
      previewActions={previewActions}
      useMainAddForm={useMainAddForm}
      renderEditActionsLeft={renderEditActionsLeft}
      bulkSessionFieldsMap={getBulkSessionFieldsMap(
        mandatoryBulkSessionFields,
        bulkFieldsDependencies,
      )}
      table={table}
      tableInfo={
        <TableWidget.Info>
          <StatFilters {...statFiltersProps} />
        </TableWidget.Info>
      }
      hiddenColumns={{ entity: true }}
      renderEditActionsRight={props => (
        <>
          <BulkEditExistingEntitiesAction
            sessionRoute={sessionRoute}
            buttonIcon="LocationPin"
            field="location"
            selector={selectorKeys.location}
            {...props}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            fieldsForBulkEdit={bulkSessionFieldsMap.location}
          />
          <BulkEditExistingEntitiesAction
            sessionRoute={sessionRoute}
            buttonIcon="Profile"
            field="line_manager"
            label="manager"
            selector={selectorKeys.all_employees_avatar_email}
            selectorField="email"
            {...props}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            fieldsForBulkEdit={bulkSessionFieldsMap.line_manager}
          />
        </>
      )}
    />
  )
}
